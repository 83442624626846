import { ReactPortal } from "../../../components/reactPortal"
import { Modal } from "../../../components/modal/modal"
import { useSelector, useDispatch } from "../../../hooks/redux"
import { CustomButton } from "../../../components/customButton"
import ttsClasses from "../../../styles/tts/ttsModal.module.scss"
import ttsSidebarClasses from "../../../styles/tts/ttsSidebar.module.scss"
import classes from "../../../styles/admin/adminModal.module.scss"
import {
  setModalVisibility,
  setDefaultModalSettings,
  updateModalSettings
} from "../../../store/slices/admin/adminSlice"
import cn from "classnames"
import { TModalUser } from "../../../@types/admin"
import { createUser } from "../../../store/slices/user/async"
import { useEffect, useState } from "react"
import { UserSettings } from "../users/userSettings"
import { AutocompleteOffFields } from "../../../components/textField/autocompleteOffFields"


export const AdminModal = () => {
  const dispatch = useDispatch()
  const modal = useSelector((state) => state.admin.modal)
  const auth_token = useSelector((state) => state.user.user.auth_token)
  const [error, setError] = useState(false)

  const handleCloseModal = () => {
    dispatch(setModalVisibility('none'))
    dispatch(setDefaultModalSettings())
    setError(false)
  }

  const handleChangeProperty = (property: Partial<TModalUser>) => {
    dispatch(updateModalSettings(property))
  }

  const addUser = () => {
    try {
      if (modal.user.auth_username) {
        const {
          auth_token: token,
          auth_username: name,
          ...rest
        } = modal.user;

        const data = {
          token,
          name,
          auth_token,
          ...rest
        }

        dispatch(createUser(data))
        handleCloseModal()
      } else {
        setError(true)
      }
    } catch (e) {
      // dispatch(addNotify({ text: `Не удалось добавить пользователя (${e})` }))
    }
  }

  useEffect(() => {
    if (error && modal.user.auth_username) {
      setError(false)
    }
  }, [modal])

  return (
    <ReactPortal>
      <Modal
        classes={{ modalContent: cn(ttsClasses.ttsModal, ttsClasses.TtsModalSettings) }}
        title="Новый пользователь"
        isOpen={modal.type === 'user'}
        handleClose={handleCloseModal}
      >
        <form autoComplete="off" className={ttsClasses.ttsModalContent}>
          {/* Компонент для обхода автозаполнения */}
          <AutocompleteOffFields />
          
          <UserSettings
            classes={{
              userInfo: classes.newUserInfo,
              userAccesses: classes.modalUserAccesses
            }}
            handleChangeSettings={handleChangeProperty}
            config={modal.user} />
          <div className={ttsClasses.ttsModalButtonGroup}>
            <CustomButton onClick={handleCloseModal}>Отмена</CustomButton>
            <CustomButton className={ttsSidebarClasses.sanitizeBtn} onClick={addUser}>
              Добавить
            </CustomButton>
          </div>
        </form>
      </Modal>
    </ReactPortal>
  )
}

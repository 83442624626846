import { createAsyncThunk } from '@reduxjs/toolkit'
import { urlAsr, urlConsole } from '../../../config'
import axios from 'axios'
import { addNotify } from '../notify/notifySlice'
import { setLoading } from './userSlice'
import { IUser } from '../../../@types/user'

export const login = createAsyncThunk('user/auth', async (auth_token: string, { dispatch }) => {
  const res = await axios.post(urlAsr + '/auth', { auth_token })

  if (res.data?.response_code === 2) {
    dispatch(addNotify({ text: res.data?.msg || 'Error' }))
    dispatch(setLoading(false))
  }

  return {
    auth_username: res.data?.auth_username,
    user_id: res.data?.user_id,
    auth_token: res.data?.auth_token,
    access_console: res.data?.access_console,
    access_asr: res.data?.access_asr,
    access_tts: res.data?.access_tts,
    access_admin: res.data?.access_admin,
    response_code: res.data?.response_code
  }
})

export const getUsers = createAsyncThunk('user/getUsers', async (auth_token: string, { dispatch }) => {
  const res = await axios.post(urlConsole + '/get_users', { auth_token })

  if (res.data?.response_code === 2) {
    return []
  }

  return res?.data?.users
})

export const createUser = createAsyncThunk('user/createUser', async (data: any, { dispatch }) => {
  try {
    const res = await axios.post(urlConsole + '/create_user', data)
    dispatch(addNotify({ text: 'Пользователь успешно добавлен' }))

    return res?.data?.users
  } catch (e) {
    dispatch(addNotify({ text: `Не удалось добавить пользователя (${e})` }))
    return []
  }
})

export const deleteUser = createAsyncThunk('user/deleteUser', async (data: any, { dispatch }) => {
  try {
    const res = await axios.post(urlConsole + '/delete_user', data)
    console.log()
    // dispatch(addNotify({ text: 'Пользователь успешно добавлен' }))

    return res?.data?.users
  } catch (e) {
    // dispatch(addNotify({ text: `Не удалось добавить пользователя (${e})` }))
    return []
  }
})

export const updateUser = createAsyncThunk('user/updateUser', async (data: any, { dispatch }) => {
  try {
    const res = await axios.post(urlConsole + '/update_user', data)
    // dispatch(addNotify({ text: 'Пользователь успешно добавлен' }))

    return res?.data?.users
  } catch (e) {
    // dispatch(addNotify({ text: `Не удалось добавить пользователя (${e})` }))
    return []
  }
})


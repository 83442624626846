import { IVoice } from '../../../@types/tts'
import Natasha from '../../../assets/natasha.webp'
import Leo from '../../../assets/leo.webp'
import Artem from '../../../assets/artem.webp'
import Tatyana from '../../../assets/tatyana.webp'
import Alexander from '../../../assets/alexander.webp'
import Pavel from '../../../assets/pavel.webp'
import Lj from '../../../assets/lj.webp'
import Raya from '../../../assets/raya.webp'
import Iseke from '../../../assets/iseke.webp'
import Murad from '../../../assets/murad.webp'
import Rina from '../../../assets/rina.webp'
import NatashaPreview from '../../../assets/natasha-preview.wav'
import LeoPreview from '../../../assets/leo-preview.wav'
import ArtemPreview from '../../../assets/artem-preview.wav'
import LjPreview from '../../../assets/lj-preview.wav'
import IsekiPreview from '../../../assets/iseki-preview.wav'
import RayaPreview from '../../../assets/raya-preview.wav'
import AlexanderPreview from '../../../assets/Alexander_preview.wav'
import PavelPreview from '../../../assets/pavel-preview.wav'
import TatyanaPreview from '../../../assets/Tatyana_preview.wav'
import RinaPreview from '../../../assets/Rina_preview.wav'
import RinaEmotionsPreview from '../../../assets/Rina_emotions_preview.wav'
import MuradPreview from '../../../assets/murad-preview.wav'

// export const initialVoices: IVoice[] = [
//   {
//     name: 'natasha',
//     picked: true,
//     sex: 'female',
//     languages: ['ru'],
//     title: 'Наташа',
//     avatar: Natasha,
//     previewAudio: NatashaPreview,
//   },
//   // {
//   //   name: 'vika',
//   //   picked: true,
//   //   sex: 'female',
//   //   languages: ['ru'],
//   //   title: 'Вика',
//   //   avatar: Natasha,
//   //   previewAudio: NatashaPreview,
//   // },
//   // {
//   //   name: 'misha',
//   //   picked: true,
//   //   sex: 'male',
//   //   languages: ['ru'],
//   //   title: 'Михаил',
//   //   avatar: Leo,
//   //   previewAudio: LeoPreview,
//   // },
//   {
//     name: 'leo',
//     picked: true,
//     sex: 'male',
//     languages: ['ru'],
//     title: 'Лео',
//     avatar: Leo,
//     previewAudio: LeoPreview,
//   },
//   {
//     name: 'artem',
//     picked: true,
//     sex: 'male',
//     languages: ['ru'],
//     title: 'Артем',
//     avatar: Artem,
//     previewAudio: ArtemPreview,
//   },
//   {
//     name: 'tatyana',
//     picked: false,
//     sex: 'female',
//     languages: ['ru'],
//     title: 'Татьяна',
//     avatar: Tatyana,
//     previewAudio: TatyanaPreview,
//   },
//   {
//     name: 'alexander',
//     picked: false,
//     sex: 'male',
//     languages: ['ru'],
//     title: 'Александр',
//     avatar: Alexander,
//     previewAudio: AlexanderPreview,
//   },
//   {
//     name: 'pavel',
//     picked: false,
//     sex: 'male',
//     languages: ['ru'],
//     title: 'Павел',
//     avatar: Pavel,
//     previewAudio: PavelPreview,
//   },
//   {
//     name: 'lj',
//     picked: false,
//     sex: 'female',
//     languages: ['en'],
//     title: 'Lj',
//     avatar: Lj,
//     previewAudio: LjPreview,
//   },
//   {
//     name: 'raya',
//     picked: false,
//     sex: 'female',
//     languages: ['kk'],
//     title: 'Raya',
//     avatar: Raya,
//     previewAudio: RayaPreview,
//     tooltip:
//       'В нашем продукте используется ISSAI KazakhTTS2 (https://doi.org/10.48550/arXiv.2201.05771), доступный по международной лицензии Creative Commons Attribution 4.0.',
//   },
//   {
//     name: 'iseke',
//     picked: false,
//     sex: 'male',
//     languages: ['kk'],
//     title: 'Iseke',
//     avatar: Iseke,
//     previewAudio: IsekiPreview,
//     tooltip:
//       'В нашем продукте используется ISSAI KazakhTTS2 (https://doi.org/10.48550/arXiv.2201.05771), доступный по международной лицензии Creative Commons Attribution 4.0.',
//   },
//   {
//     name: 'azerbaijani',
//     picked: false,
//     sex: 'male',
//     languages: ['az'],
//     title: 'Мурад',
//     avatar: Murad,
//     previewAudio: MuradPreview,
//   },
//   {
//     name: 'rina',
//     picked: false,
//     sex: 'female',
//     languages: ['ru'],
//     title: 'Рина',
//     avatar: Rina,
//     previewAudio: RinaPreview,
//     tooltip: 'Премиум голос',
//   },
//   {
//     name: 'rina_emotions',
//     picked: false,
//     sex: 'female',
//     languages: ['ru'],
//     title: 'Рина',
//     avatar: Rina,
//     previewAudio: RinaEmotionsPreview,
//     emotion: 'neutral',
//     tooltip: 'Премиум голос с возможностью выбора эмоциональности',
//   },
// ]

export const initialTtsSettings = {
  pitch: 1,
  rate: 1,
  volume: 0,
}

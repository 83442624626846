import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IStateConsole } from '../../../@types/console'
import { consoleSend } from './async'

const initialState: IStateConsole = {
  consoleMode: 'asr',
  sessionsCount: 5,
  startDate: null,
  endDate: null,
  selectedUsers: [],
  users: [],
  loading: false,
  downloading: false,
  error: false,
  errorText: '',
  sessions: [],
  stats: null,
  selectedSessionIds: [],
  currentAudio: {
    id: '',
    currentTime: 0,
    totalTime: 0,
  },
}

const consoleSlice = createSlice({
  name: 'console',
  initialState,
  reducers: {
    updateConsoleMode(state, action: PayloadAction<'asr' | 'tts'>) {
      const consoleMode = action.payload

      return {
        ...state,
        consoleMode
      }
    },
    updateSessionCount(state, action: PayloadAction<number>) {
      const sessionsCount = action.payload

      return {
        ...state,
        sessionsCount
      }
    },
    resetFiltersState(state) {
      return {
        ...initialState,
        selectedUsers: state.selectedUsers,
        users: state.users
      }
    },
    setStartDate(state, action: PayloadAction<Date | null>) {
      const startDate = action.payload

      return {
        ...state,
        startDate
      }
    },
    setEndDate(state, action: PayloadAction<Date | null>) {
      const endDate = action.payload

      return {
        ...state,
        endDate
      }
    },
    consoleSetAudio: (state, action) => {
      if (action.payload?.id && action.payload?.id !== state.currentAudio.id) state.currentAudio.id = action.payload.id
      if (action.payload.currentTime) state.currentAudio.currentTime = action.payload.currentTime
    },
    setConsoleLoading(state, action: PayloadAction<boolean>) {
      const loading = action.payload

      return {
        ...state,
        loading
      }
    },
    setConsoleDownloading(state, action: PayloadAction<boolean>) {
      const downloading = action.payload

      return {
        ...state,
        downloading
      }
    },
    setErrorText(state, action: PayloadAction<string>) {
      return {
        ...state,
        errorText: action.payload
      }
    },
    setConsoleError(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        error: action.payload
      }
    },
    setSelectedSessions(state, action: PayloadAction<number>) {
      const id = action.payload
      const currentState = state.selectedSessionIds
      const selectedSessionIds = currentState.includes(id)
        ? currentState.filter(item => item !== id)
        : [...currentState, id]

      return {
        ...state,
        selectedSessionIds
      }
    },
    setSelectedUsers(state, action: PayloadAction<string>) {
      const user = action.payload

      return {
        ...state,
        selectedUsers: state.selectedUsers.includes(user)
          ? state.selectedUsers.filter(item => item !== user)
          : [...state.selectedUsers, user]
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(consoleSend.pending, (state) => {
        state.loading = true
        state.selectedSessionIds = []
        state.error = false
      })
      .addCase(consoleSend.fulfilled, (state, action) => {
        const sessions = action?.payload?.sessions || []
        const stats = action?.payload?.stats || null

        if (stats?.service_type === 'asr') {
          return {
            ...state,
            stats: {
              ...stats,
              asr: {
                total_15_sec_pieces: stats.total_15_sec_pieces,
                total_duration: stats.total_duration,
              },
              tts: {}
            },
            sessions: sessions,
            loading: false
          }
        } else if (stats.service_type === 'tts') {
          return {
            ...state,
            stats: {
              ...stats,
              asr: {},
              tts: {
                total_length: stats.total_length
              }
            },
            sessions: sessions,
            loading: false
          }
        }
      })
      .addCase(consoleSend.rejected, (state) => {
        state.loading = false
        state.error = true
      })
  }
})

const { actions, reducer } = consoleSlice

export const {
  updateConsoleMode,
  updateSessionCount,
  resetFiltersState,
  setStartDate,
  setEndDate,
  consoleSetAudio,
  setConsoleLoading,
  setConsoleError,
  setErrorText,
  setSelectedSessions,
  setConsoleDownloading,
  setSelectedUsers
} = actions

export default reducer

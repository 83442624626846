import { FC, ChangeEvent, useState, MouseEvent } from "react"
import { CustomIcon } from "../customIcon/customIcon"
import cn from 'classnames'
import classes from '../../styles/textField.module.scss'


export const TextField: FC<{
    localeText?: string
    value?: string
    type: 'text' | 'password'
    handleChangeInput?: (e: ChangeEvent<HTMLInputElement>) => void
    error?: boolean
    disabled?: boolean
}> = ({
    localeText,
    value,
    type = 'text',
    handleChangeInput,
    error,
    disabled
}) => {
        const [showText, setShowText] = useState<boolean>(false)

        const toggleTokenVisibility = (e: MouseEvent<HTMLButtonElement>) => {
            e.preventDefault()
            setShowText((p) => !p)
        }

        return (
            <div className={cn(classes.textFieldWrapper, error && classes.textFieldError)}>
                <input
                    autoComplete={type === "password" ? "new-password" : "off"}
                    disabled={disabled}
                    type={showText ? "text" : type}
                    className={cn(classes.textFieldInput, type === 'password' && classes.token)}
                    placeholder={error ? 'Обязательное поле' : (localeText || "Поиск")}
                    value={value || ''}
                    onChange={handleChangeInput}
                    name={type === "password" ? `dummy-password-${Math.random()}` : undefined}
                    readOnly={type === "password" ? true : false}
                    onFocus={(e) => type === "password" && e.currentTarget.removeAttribute("readonly")}
                />
                {value !== '' && type === 'password' && (
                    <button onClick={toggleTokenVisibility}>
                        <CustomIcon icon={showText ? 'hideIcon' : 'showIcon'} />
                    </button>
                )}
            </div>
        )
    }

import { memo } from "react"
import { ISessionPagination } from "../@types/console"
import classes from "../styles/pagination.module.scss"
import cn from "classnames"
import { CustomIcon } from "./customIcon/customIcon"


export const Pagination = memo(({ currentPage, totalPages, onPageChange }: ISessionPagination) => {
    const getPageNumbers = () => {
        const pages: (number | string)[] = []

        if (totalPages <= 7) {
            for (let i = 1; i <= totalPages; i++) {
                pages.push(i);
            }
        } else {
            pages.push(1)

            if (currentPage <= 4) {
                for (let i = 2; i <= 5; i++) {
                    pages.push(i)
                }
                pages.push("...")
                pages.push(totalPages)
            } 
            else if (currentPage >= 5 && currentPage < totalPages - 3) {
                pages.push("...")
                for (let i = currentPage - 1; i <= currentPage + 1; i++) {
                    pages.push(i)
                } 
                pages.push("...")
                pages.push(totalPages)
            } else {
                pages.push("...")
                for (let i = totalPages - 4; i <= totalPages; i++) {
                    pages.push(i)
                }
            }
        }
        console.log(pages)

        return pages
    }

    return (
        <div className={classes.pagination}>
            <button
                disabled={currentPage === 1}
                className={classes.paginationButton}
                onClick={() => onPageChange(currentPage - 1)}
            >
                <CustomIcon icon="arrowRight" />
            </button>
            {getPageNumbers().map((page, index) => (
                typeof page === "number" ? (
                    <button
                        key={"page_" + page}
                        disabled={page === currentPage}
                        onClick={() => onPageChange(page)}
                        className={cn(classes.paginationButton, page === currentPage && classes.active)}
                    >
                        {page}
                    </button>
                ) : (
                    <span key={"dots_" + index} className={classes.paginationElipsis}>
                        {page}
                    </span>
                )

            ))}
            <button
                disabled={currentPage === totalPages}
                className={classes.paginationButton}
                onClick={() => onPageChange(currentPage + 1)}
            >
                <CustomIcon icon="arrowRight" />
            </button>
        </div>
    )
})

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getLocalStorageInfo, setLocalStorageInfo } from '../../../tools'
import { login, getUsers, createUser, deleteUser, updateUser } from './async'
import { IUser, IStateUser } from '../../../@types/user'

const initialUser: IUser = {
  auth_token: '',
  user_id: 0,
  access_admin: false,
  access_asr: false,
  access_console: false,
  access_tts: false,
  auth_username: '',
}

const initialState: IStateUser = {
  loading: false,
  changes: [],
  users: [],
  initialUsers: [],
  user: getLocalStorageInfo('user') || initialUser,
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserStore: (state, action) => {
      setLocalStorageInfo('user', { ...getLocalStorageInfo('user'), ...action.payload })
      return { ...state, ...action.payload }
    },
    logout: (state) => {
      setLocalStorageInfo('user', { ...getLocalStorageInfo('user'), ...initialUser })
      return { ...state, user: initialUser }
    },
    setLoading: (state, action) => {
      return { ...state, loading: action.payload }
    },
    updateUserSettings(state, action) {
      const changedUsers = state.changes

      return {
        ...state,
        changes: changedUsers.includes(action.payload.user_id)
        ? changedUsers.filter(id => {
          const originalUser = state.initialUsers.find(user => user.user_id === id)
          const user = state.users.find(user => user.user_id === id)

          return JSON.stringify({...user, ...action.payload }) !== JSON.stringify(originalUser)
        })
        : [ ...changedUsers, action.payload.user_id ],
        users: state?.users?.map(user =>
          user.user_id === action.payload.user_id
            ? { ...user, ...action.payload }
            : user
        )
      }
    },
    removeUser(state, action: PayloadAction<number>) {
      return {
        ...state,
        users: state?.users?.filter(user =>
          user.user_id !== action.payload
        ),
        changes: [...state.changes, action.payload]
      }
    },
    setInitialUsersState(state) {
      return {
        ...initialState
      }
    }
  },
  extraReducers: (build) => {
    build.addCase(login.pending, (state) => {
      state.loading = true
    })
    build.addCase(login.fulfilled, (state, action: PayloadAction<(IUser & { response_code: number }) | null>) => {
      if (!action.payload) return state

      const { response_code, ...user } = action.payload

      try {
        setLocalStorageInfo('user', { ...getLocalStorageInfo('user'), ...user })
        return { ...state, user, loading: false }
      } catch (e) {
        return { ...state, loading: false }
      }
    })
    build.addCase(login.rejected, (state) => {
      state.loading = false
    })
    build.addCase(getUsers.fulfilled, (state, action: PayloadAction<IUser[]>) => {
      return { ...state, users: action.payload, initialUsers: action.payload }
    })
    build.addCase(createUser.fulfilled, (state, action: PayloadAction<IUser[]>) => {
      return { ...state, users: action.payload }
    })
    build.addCase(deleteUser.fulfilled, (state, action: PayloadAction<IUser[]>) => {
      return { ...state, users: action.payload }
    })
    build.addCase(updateUser.fulfilled, (state, action: PayloadAction<IUser[]>) => {
      return { ...state, users: action.payload }
    })
  },
})

const { actions, reducer } = userSlice

export const {
  setUserStore,
  logout,
  setLoading,
  updateUserSettings,
  removeUser,
  setInitialUsersState
} = actions

export default reducer

import { FC, memo, useState, useEffect, useRef } from 'react'
import { CustomIcon } from './customIcon/customIcon'
import customMultiSelectClasses from '../styles/customMultiSelect.module.scss'
import { OutClick } from './outClick'
import cn from 'classnames'
import { Checkbox } from './checkbox/checkbox'

const ITEM_HEIGHT = 39
const MAX_ITEMS = 5

export const CustomMultiSelect: FC<{
  items: string[]
  selectedItems: string[]
  localeText?: string
  handleChange: (option: string) => void
  classes?: {
    outClickClassName?: string
    selectorWrapperClassName?: string
    selectListItemsClassName?: string
    selectItem?: string
  }
}> = memo(({ items, selectedItems, handleChange, classes, localeText }) => {
  const [open, setOpen] = useState(false)
  const [scrollPosition, setScrollPosition] = useState(0)
  const listRef = useRef<HTMLDivElement>(null)

  const handleCloseOpen = () => {
    setOpen((p) => !p)
  }

  const handleClickItem = (item: string) => {
    handleChange(item)
  }

  useEffect(() => {
    if (open && listRef.current) {
      listRef.current.scrollTop = scrollPosition
    }
  }, [open, scrollPosition])

  const handleScroll = () => {
    if (listRef.current) {
      setScrollPosition(listRef.current.scrollTop)
    }
  }

  return (
    <OutClick
      className={cn(customMultiSelectClasses.outClickWrapper, classes?.outClickClassName && classes.outClickClassName)}
      onClick={open ? handleCloseOpen : () => null}
    >
      <>
        <div
          className={cn(customMultiSelectClasses.customSelectWrapper, classes?.selectorWrapperClassName)}
          onClick={handleCloseOpen}
        >
          <div className={customMultiSelectClasses.customSelectActive}>
            {
              selectedItems.length > 0 ? (
                <span>{selectedItems?.map(item => item).join(', ')}</span>
              ) : (
                <span style={{ color: "#808a9a" }}>{localeText}</span>
              )
            }
            <CustomIcon icon={open ? 'arrowDown' : 'arrowRight'} />
          </div>
        </div>
        {open && (
          <div
            ref={listRef}
            onScroll={handleScroll}
            style={{ maxHeight: MAX_ITEMS * ITEM_HEIGHT }}
            className={cn(
              customMultiSelectClasses.customSelectListItems,
              classes?.selectListItemsClassName && classes?.selectListItemsClassName,
              open && customMultiSelectClasses.customSelectorOpened
            )}
          >
            {items.map((item, index) => {
              return (
                <div
                  key={item + index}
                  className={cn(
                    customMultiSelectClasses.customMultiSelectItem
                  )}
                  onClick={() => handleClickItem(item)}
                >
                  <span>{item}</span>
                  <Checkbox isChecked={selectedItems.includes(item)} handleCheckboxChange={() => { }} />
                </div>
              )
            })}
          </div>
        )}
      </>
    </OutClick>
  )
})
